<template>

  <div class="row" ref="roleForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <div class="row table-full-width">
              <el-input type="search"
                        class="mb-3"
                        style="width: 200px"
                        placeholder="Search records"
                        v-model="searchQuery"
                        aria-controls="datatables"/>
              <el-select
                class="select-default mb-3 mx-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div class="col-sm-12">
                <el-table class="table"
                          :data="queriedData">
                  <el-table-column v-for="column in tableColumns"
                                   :key="column.label"
                                   :min-width="column.minWidth"
                                   :align="column.align"
                                   :sortable="column.sortable"
                                   :prop="column.value"
                                   :label="column.label"
                  >

                  </el-table-column>

                  <el-table-column
                    :min-width="120"
                    fixed="right"
                    align="center"
                    label="Active">
                    <template slot-scope="props">

                      <l-switch
                        v-model="props.row.is_active">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>

                    </template>
                  </el-table-column>

                </el-table>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                  <div class="">
                    <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
                  </div>
                  <l-pagination class="pagination-no-border"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total">
                  </l-pagination>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/roles/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option, Table, TableColumn} from 'element-ui';
import {Switch as LSwitch} from '@/components'
import {Pagination as LPagination} from 'src/components/index'
import Fuse from 'fuse.js'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    LPagination,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== '') {
        result = this.tableData.filter( ({ title }) => title.toLowerCase().includes(this.searchQuery));
        this.pagination.total = result.length
      }
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data() {
    return {
      tableData:[],
      fuseSearch: null,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      propsToSearch: ['title'],
      searchQuery: '',
      tableColumns: [
        {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
      ],
      editMode: false,
      loader: {},
      id: undefined,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        permissions: []
      },
      permissions: [],
    };
  },
  mounted() {
    let data = {
      'lang': this.lang
    }
    this.loader = this.$loading.show({
      container: this.$refs.roleForm
    });
    this.axios.post("roles/builder", data).then((response) => {
      this.permissions = response.data.permissions;
      this.id = this.$route.params['id'];
      this.tableData = this.permissions.map((item) => {return {...item,'is_active':false}});
      this.loader.hide();

      if (this.id !== undefined) {

        this.editMode = true;
        this.formTitle = "Edit Role";
        this.getRole();

      } else {
        this.editMode = false;
        this.formTitle = "Add Role";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    });
  },


  methods: {


    getRole() {
      this.axios.get("roles/get/" + this.id).then((response) => {
        this.formData.name = response.data.name;
        this.formData.permissions = response.data.permissions.map(p => {
          return p.id
        });
        this.tableData.map(item => {
          if(this.formData.permissions.includes(item.id)){
            item.is_active = true;
          }else{
            item.is_active = false;
          }
        });
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Role Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.formData.permissions = this.tableData.filter(element => element.is_active === true);
      this.formData.permissions = this.formData.permissions.map(p => {
        return p.id
      });

      if (this.editMode === true) {

        request = this.axios.put("roles/update/" + this.id, this.formData);
        successMessage = "Role Updated Successfully";
      } else {
        request = this.axios.post("roles/create", this.formData);
        successMessage = "Role Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/roles/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

  }
}
</script>

<style>
</style>
